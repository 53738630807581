import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2><a id="character-classes"></a>Character Classes</h2>
    <h3><a id="cleric"></a>CLERIC</h3>
    <p className="initial"><a href="http://www.wizards.com/dnd/images/ph35_gallery/PHB35_PG31_WEB.jpg"><img className="art-wide" src="pict.jpg" title="" alt="pict" style={{
          "border": "0px solid",
          "width": "73px",
          "height": "75px"
        }} /></a><b>Alignment</b>:
A cleric&rsquo;s alignment must be
within one step of his deity&rsquo;s (that is, it may be one step away on
either the lawful&ndash;chaotic axis or the good&ndash;evil axis, but not both). A
cleric may not be neutral unless his deity&rsquo;s alignment is also neutral.</p>
    <p><b>Hit Die</b>: d8.</p>
    <h6>Class Skills</h6>
    <p className="initial">The cleric&rsquo;s class skills (and the key ability
for each skill) are <a href="skillsAll.html#concentration" style={{
        "color": "rgb(87, 158, 182)"
      }}>Concentration</a>
(Con), <a href="skillsAll.html#craft" style={{
        "color": "rgb(87, 158, 182)"
      }}>Craft</a>
(Int), <a href="skillsAll.html#diplomacy" style={{
        "color": "rgb(87, 158, 182)"
      }}>Diplomacy</a> (Cha), <a href="skillsAll.html#heal" style={{
        "color": "rgb(87, 158, 182)"
      }}>Heal</a>
(Wis), <a href="skillsAll.html#knowledge" style={{
        "color": "rgb(87, 158, 182)"
      }}>Knowledge</a>
(arcana/history/religion/the planes) (Int), <a href="skillsAll.html#profession" style={{
        "color": "rgb(87, 158, 182)"
      }}>Profession</a>
(Wis), and <a href="skillsAll.html#spellcraft" style={{
        "color": "rgb(87, 158, 182)"
      }}>Spellcraft</a> (Int).</p>
    <p><b>Domains and Class Skills</b>: A cleric who chooses the <a href="clericDomains.html#animal" style={{
        "color": "rgb(87, 158, 182)"
      }}>Animal</a>
or <a href="clericDomains.html#plant" style={{
        "color": "rgb(87, 158, 182)"
      }}>Plant</a>
domain adds <a href="skillsAll.html#knowledge" style={{
        "color": "rgb(87, 158, 182)"
      }}>Knowledge</a> (nature) (Int) to the
cleric
class skills listed above. A cleric who chooses the <a href="clericDomains.html#knowledge" style={{
        "color": "rgb(87, 158, 182)"
      }}>Knowledge</a>
domain
adds all Knowledge (Int) skills to the list. A cleric who chooses the <a href="clericDomains.html#travel" style={{
        "color": "rgb(87, 158, 182)"
      }}>Travel</a>
domain adds <a href="skillsAll.html#survival" style={{
        "color": "rgb(87, 158, 182)"
      }}>Survival</a>
(Wis) to the list. A cleric who chooses the <a href="clericDomains.html#trickery" style={{
        "color": "rgb(87, 158, 182)"
      }}>Trickery</a>
domain adds <a href="skillsAll.html#bluff" style={{
        "color": "rgb(87, 158, 182)"
      }}>Bluff</a>
(Cha), <a href="skillsAll.html#disguise" style={{
        "color": "rgb(87, 158, 182)"
      }}>Disguise</a> (Cha), and <a href="skillsAll.html#hide" style={{
        "color": "rgb(87, 158, 182)"
      }}>Hide</a>
(Dex) to the
list. See Deity, Domains, and Domain Spells, below, for more
information.</p>
    <p><b>Skill Points at 1st Level</b>: (2 + Int modifier) x 4.</p>
    <p><b>Skill Points at Each Additional Level</b>: 2 + Int modifier.</p>
    <p><b><a id="table-the-cleric"></a>Table: The Cleric</b></p>
    <table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr valign="bottom">
          <th rowSpan="2" style={{
            "width": "6%"
          }}>Level</th>
          <th rowSpan="2" style={{
            "width": "12%"
          }}>Base
Attack Bonus</th>
          <th rowSpan="2" style={{
            "width": "6%"
          }}>Fort
Save</th>
          <th rowSpan="2" style={{
            "width": "6%"
          }}>Ref
Save</th>
          <th rowSpan="2" style={{
            "width": "6%"
          }}>Will
Save</th>
          <th rowSpan="2" style={{
            "width": "24%"
          }}>Special</th>
          <th colSpan="10" style={{
            "textAlign": "center"
          }}>Spells per Day<sup>1</sup></th>
        </tr>
        <tr>
          <th style={{
            "width": "4%"
          }}>0</th>
          <th style={{
            "width": "4%"
          }}>1st</th>
          <th style={{
            "width": "4%"
          }}>2nd</th>
          <th style={{
            "width": "4%"
          }}>3rd</th>
          <th style={{
            "width": "4%"
          }}>4th</th>
          <th style={{
            "width": "4%"
          }}>5th</th>
          <th style={{
            "width": "4%"
          }}>6th</th>
          <th style={{
            "width": "4%"
          }}>7th</th>
          <th style={{
            "width": "4%"
          }}>8th</th>
          <th style={{
            "width": "4%"
          }}>9th</th>
        </tr>
        <tr className="odd-row">
          <td>1st</td>
          <td>+0</td>
          <td>+2</td>
          <td>+0</td>
          <td>+2</td>
          <td>Turn or rebuke undead</td>
          <td>3</td>
          <td>1+1</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
        </tr>
        <tr>
          <td>2nd</td>
          <td>+1</td>
          <td>+3</td>
          <td>+0</td>
          <td>+3</td>
          <td>&nbsp;</td>
          <td>4</td>
          <td>2+1</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
        </tr>
        <tr className="odd-row">
          <td>3rd</td>
          <td>+2</td>
          <td>+3</td>
          <td>+1</td>
          <td>+3</td>
          <td>&nbsp;</td>
          <td>4</td>
          <td>2+1</td>
          <td>1+1</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
        </tr>
        <tr>
          <td>4th</td>
          <td>+3</td>
          <td>+4</td>
          <td>+1</td>
          <td>+4</td>
          <td>&nbsp;</td>
          <td>5</td>
          <td>3+1</td>
          <td>2+1</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
        </tr>
        <tr className="odd-row">
          <td>5th</td>
          <td>+3</td>
          <td>+4</td>
          <td>+1</td>
          <td>+4</td>
          <td>&nbsp;</td>
          <td>5</td>
          <td>3+1</td>
          <td>2+1</td>
          <td>1+1</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
        </tr>
        <tr>
          <td>6th</td>
          <td>+4</td>
          <td>+5</td>
          <td>+2</td>
          <td>+5</td>
          <td>&nbsp;</td>
          <td>5</td>
          <td>3+1</td>
          <td>3+1</td>
          <td>2+1</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
        </tr>
        <tr className="odd-row">
          <td>7th</td>
          <td>+5</td>
          <td>+5</td>
          <td>+2</td>
          <td>+5</td>
          <td>&nbsp;</td>
          <td>6</td>
          <td>4+1</td>
          <td>3+1</td>
          <td>2+1</td>
          <td>1+1</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
        </tr>
        <tr>
          <td>8th</td>
          <td>+6/+1</td>
          <td>+6</td>
          <td>+2</td>
          <td>+6</td>
          <td>&nbsp;</td>
          <td>6</td>
          <td>4+1</td>
          <td>3+1</td>
          <td>3+1</td>
          <td>2+1</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
        </tr>
        <tr className="odd-row">
          <td>9th</td>
          <td>+6/+1</td>
          <td>+6</td>
          <td>+3</td>
          <td>+6</td>
          <td>&nbsp;</td>
          <td>6</td>
          <td>4+1</td>
          <td>4+1</td>
          <td>3+1</td>
          <td>2+1</td>
          <td>1+1</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
        </tr>
        <tr>
          <td>10th</td>
          <td>+7/+2</td>
          <td>+7</td>
          <td>+3</td>
          <td>+7</td>
          <td>&nbsp;</td>
          <td>6</td>
          <td>4+1</td>
          <td>4+1</td>
          <td>3+1</td>
          <td>3+1</td>
          <td>2+1</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
        </tr>
        <tr className="odd-row">
          <td>11th</td>
          <td>+8/+3</td>
          <td>+7</td>
          <td>+3</td>
          <td>+7</td>
          <td>&nbsp;</td>
          <td>6</td>
          <td>5+1</td>
          <td>4+1</td>
          <td>4+1</td>
          <td>3+1</td>
          <td>2+1</td>
          <td>1+1</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
        </tr>
        <tr>
          <td>12th</td>
          <td>+9/+4</td>
          <td>+8</td>
          <td>+4</td>
          <td>+8</td>
          <td>&nbsp;</td>
          <td>6</td>
          <td>5+1</td>
          <td>4+1</td>
          <td>4+1</td>
          <td>3+1</td>
          <td>3+1</td>
          <td>2+1</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
        </tr>
        <tr className="odd-row">
          <td>13th</td>
          <td>+9/+4</td>
          <td>+8</td>
          <td>+4</td>
          <td>+8</td>
          <td>&nbsp;</td>
          <td>6</td>
          <td>5+1</td>
          <td>5+1</td>
          <td>4+1</td>
          <td>4+1</td>
          <td>3+1</td>
          <td>2+1</td>
          <td>1+1</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
        </tr>
        <tr>
          <td>14th</td>
          <td>+10/+5</td>
          <td>+9</td>
          <td>+4</td>
          <td>+9</td>
          <td>&nbsp;</td>
          <td>6</td>
          <td>5+1</td>
          <td>5+1</td>
          <td>4+1</td>
          <td>4+1</td>
          <td>3+1</td>
          <td>3+1</td>
          <td>2+1</td>
          <td>&mdash;</td>
          <td>&mdash;</td>
        </tr>
        <tr className="odd-row">
          <td>15th</td>
          <td>+11/+6/+1</td>
          <td>+9</td>
          <td>+5</td>
          <td>+9</td>
          <td>&nbsp;</td>
          <td>6</td>
          <td>5+1</td>
          <td>5+1</td>
          <td>5+1</td>
          <td>4+1</td>
          <td>4+1</td>
          <td>3+1</td>
          <td>2+1</td>
          <td>1+1</td>
          <td>&mdash;</td>
        </tr>
        <tr>
          <td>16th</td>
          <td>+12/+7/+2</td>
          <td>+10</td>
          <td>+5</td>
          <td>+10</td>
          <td>&nbsp;</td>
          <td>6</td>
          <td>5+1</td>
          <td>5+1</td>
          <td>5+1</td>
          <td>4+1</td>
          <td>4+1</td>
          <td>3+1</td>
          <td>3+1</td>
          <td>2+1</td>
          <td>&mdash;</td>
        </tr>
        <tr className="odd-row">
          <td>17th</td>
          <td>+12/+7/+2</td>
          <td>+10</td>
          <td>+5</td>
          <td>+10</td>
          <td>&nbsp;</td>
          <td>6</td>
          <td>5+1</td>
          <td>5+1</td>
          <td>5+1</td>
          <td>5+1</td>
          <td>4+1</td>
          <td>4+1</td>
          <td>3+1</td>
          <td>2+1</td>
          <td>1+1</td>
        </tr>
        <tr>
          <td>18th</td>
          <td>+13/+8/+3</td>
          <td>+11</td>
          <td>+6</td>
          <td>+11</td>
          <td>&nbsp;</td>
          <td>6</td>
          <td>5+1</td>
          <td>5+1</td>
          <td>5+1</td>
          <td>5+1</td>
          <td>4+1</td>
          <td>4+1</td>
          <td>3+1</td>
          <td>3+1</td>
          <td>2+1</td>
        </tr>
        <tr className="odd-row">
          <td>19th</td>
          <td>+14/+9/+4</td>
          <td>+11</td>
          <td>+6</td>
          <td>+11</td>
          <td>&nbsp;</td>
          <td>6</td>
          <td>5+1</td>
          <td>5+1</td>
          <td>5+1</td>
          <td>5+1</td>
          <td>5+1</td>
          <td>4+1</td>
          <td>4+1</td>
          <td>3+1</td>
          <td>3+1</td>
        </tr>
        <tr>
          <td className="last-row">20th</td>
          <td className="last-row">+15/+10/+5</td>
          <td className="last-row">+12</td>
          <td className="last-row">+6</td>
          <td className="last-row">+12</td>
          <td className="last-row">&nbsp;</td>
          <td className="last-row">6</td>
          <td className="last-row">5+1</td>
          <td className="last-row">5+1</td>
          <td className="last-row">5+1</td>
          <td className="last-row">5+1</td>
          <td className="last-row">5+1</td>
          <td className="last-row">4+1</td>
          <td className="last-row">4+1</td>
          <td className="last-row">4+1</td>
          <td className="last-row">4+1</td>
        </tr>
        <tr>
          <td colSpan="16">1 <i>In addition to the stated number of
spells per day for 1st- through 9th-level spells, a cleric gets a
domain spell for each spell level, starting at 1st.
The &ldquo;+1&rdquo; in the entries on this table represents that spell. Domain
spells are in addition to any bonus spells the cleric may receive for
having a high Wisdom score.</i></td>
        </tr>
      </tbody>
    </table>
    <h6><a id="cleric-class-features"></a>Class Features</h6>
    <p className="initial">All of the following are class features of the
cleric.</p>
    <p><b>Weapon and Armor Proficiency</b>: Clerics are proficient
with all simple weapons, with all types of armor (light, medium, and
heavy), and with shields (except tower shields).</p>
    <p>A cleric who chooses the <a href="clericDomains.html#war" style={{
        "color": "rgb(87, 158, 182)"
      }}>War</a> domain receives the <a href="featsAll.html#weapon-focus" style={{
        "color": "rgb(87, 158, 182)"
      }}>Weapon
Focus</a>
feat related to his deity&rsquo;s weapon as a bonus feat. He also receives
the appropriate Martial Weapon Proficiency feat as a bonus feat, if the
weapon falls into that category.</p>
    <p><a id="cleric-aura"></a><b>Aura (Ex)</b>: A cleric of a chaotic, evil, good, or lawful
deity has a particularly powerful aura corresponding to the deity&rsquo;s
alignment (see the detect evil spell for details). Clerics who don&rsquo;t
worship a specific deity but choose the Chaotic, Evil, Good, or Lawful
domain have a similarly powerful aura of the corresponding alignment.</p>
    <p><a id="cleric-spells"></a><b>Spells</b>: A cleric casts divine spells, which are drawn
from the <a style={{
        "color": "#579eb6"
      }} href="clericSpells.html">cleric spell list</a>. However, his alignment may restrict him
from casting certain spells opposed to his moral or ethical beliefs;
see Chaotic, Evil, Good, and Lawful Spells, below. A cleric must choose
and prepare his spells in advance (see below).</p>
    <p>To prepare or cast a spell, a cleric must have a Wisdom score
equal to at least 10 + the spell level. The Difficulty Class for a
saving throw against a cleric&rsquo;s spell is 10 + the spell level + the
cleric&rsquo;s Wisdom modifier.</p>
    <p>Like other spellcasters, a cleric can cast only a certain
number of spells of each spell level per day. His base daily spell
allotment is given on Table: The Cleric. In addition, he receives bonus
spells per day if he has a high Wisdom score. A cleric also gets one
domain spell of each spell level he can cast, starting at 1st level.
When a cleric prepares a spell in a domain spell slot, it must come
from one of his two domains (see Deities, Domains, and Domain Spells,
below).</p>
    <p>Clerics meditate or pray for their spells. Each cleric must
choose a time at which he must spend 1 hour each day in quiet
contemplation or supplication to regain his daily allotment of spells.
Time spent resting has no effect on whether a cleric can prepare
spells. A cleric may prepare and cast any spell on the cleric spell
list, provided that he can cast spells of that level, but he must
choose which spells to prepare during his daily meditation.</p>
    <p><b>Deity, Domains, and Domain Spells</b>: A cleric&rsquo;s deity
influences his alignment, what magic he can perform, his values, and
how others see him. A cleric chooses two <a style={{
        "color": "#579eb6"
      }} href="clericDomains.html">domains</a> from among those
belonging to his deity. A cleric can select an alignment domain (Chaos,
Evil, Good, or Law) only if his alignment matches that domain.</p>
    <p>If a cleric is not devoted to a particular deity, he still
selects two domains to represent his spiritual inclinations and
abilities. The restriction on alignment domains still applies.</p>
    <p>Each domain gives the cleric access to a domain spell at each
spell level he can cast, from 1st on up, as well as a granted power.
The cleric gets the granted powers of both the domains selected. With
access to two domain spells at a given spell level, a cleric prepares
one or the other each day in his domain spell slot. If a domain spell
is not on the cleric spell list, a cleric can prepare it only in his
domain spell slot.</p>
    <p><b>Spontaneous Casting</b>: A good cleric (or a neutral cleric
of a good deity) can channel stored spell energy into healing spells
that the cleric did not prepare ahead of time. The cleric can &ldquo;lose&rdquo;
any prepared spell that is not a domain spell in order to cast any cure
spell of the same spell level or lower (a cure spell is any spell with
&ldquo;cure&rdquo; in its name).</p>
    <p>An evil cleric (or a neutral cleric of an evil deity), can&rsquo;t
convert prepared spells to cure spells but can convert them to inflict
spells (an inflict spell is one with &ldquo;inflict&rdquo; in its name).</p>
    <p>A cleric who is neither good nor evil and whose deity is
neither good nor evil can convert spells to either cure spells or
inflict spells (player&rsquo;s choice). Once the player makes this choice, it
cannot be reversed. This choice also determines whether the cleric
turns or commands undead (see below).</p>
    <p><a id="cleric-chaotic-evil-good-and-lawful-spells"></a><b>Chaotic, Evil, Good, and Lawful Spells</b>: A cleric can&rsquo;t
cast spells of an alignment opposed to his own or his deity&rsquo;s (if he
has one). Spells associated with particular alignments are indicated by
the chaos, evil, good, and law descriptors in their spell descriptions.</p>
    <p><a id="cleric-turn-or-rebuke-undead"></a><b>Turn or Rebuke Undead (Su)</b>: Any cleric, regardless of
alignment, has the power to affect undead creatures by channeling the
power of his faith through his holy (or unholy) symbol (see Turn or
Rebuke Undead).</p>
    <p>A good cleric (or a neutral cleric who worships a good deity)
can turn or destroy undead creatures. An evil cleric (or a neutral
cleric who worships an evil deity) instead rebukes or commands such
creatures. A neutral cleric of a neutral deity must choose whether his
turning ability functions as that of a good cleric or an evil cleric.
Once this choice is made, it cannot be reversed. This decision also
determines whether the cleric can cast spontaneous cure or inflict
spells (see above).</p>
    <p>A cleric may attempt to <a href="specialAttacks.html#turn" style={{
        "color": "rgb(87, 158, 182)"
      }}>turn undead</a> a number of times
per day
equal to 3 + his Charisma modifier. A cleric with 5 or more ranks in
Knowledge (religion) gets a +2 bonus on turning checks against undead.</p>
    <p><a id="cleric-bonus-languages"></a><b>Bonus Languages</b>: A cleric&rsquo;s bonus language options
include Celestial, Abyssal, and Infernal (the languages of good,
chaotic evil, and lawful evil outsiders, respectively). These choices
are in addition to the bonus languages available to the character
because of his race.</p>
    <h6><a id="ex-clerics"></a>Ex-Clerics</h6>
    <p className="initial">A cleric who grossly violates the code of
conduct required by his god loses all spells and class features, except
for armor and shield proficiencies and proficiency with simple weapons.
He cannot thereafter gain levels as a cleric of that god until he
atones (see the atonement spell description).</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      